import { getPersonName } from '@/helper/formatter'
import { supabase } from '@/supabase'
import { Tables } from '@/types/database'
import { defineStore } from 'pinia'
import { computed, Ref, ref } from 'vue'
import { CRUD, FEATURE } from '@/types/extendDatabase'

export type PersonWithRights = {
  user_id: string
  id: number
  first_name: string
  last_name: string
  mail: string
  roles: {
    id: number
    role: string
  }[]
  assignedBranches: number[]
  assignedGroups: number[]
}

export const useRightsStore = defineStore('rights', () => {
  let roles: Ref<Tables<'rights_role'>[]> = ref([])
  let persons: Ref<Tables<'person'>[]> = ref([])
  let rights: Ref<Tables<'rights_user_role'>[]> = ref([])
  const userRights: Ref<Tables<'rights_access_control_list'>[]> = ref([])
  const userId: Ref<string> = ref('')
  let personWithRights: Ref<PersonWithRights[]> = ref([])
  const currentUserRight = computed(() => rights.value.find((r) => r.user_id === userId.value))

  let initDone = new Promise(async (resolve) => {
    await refetch()
    resolve(null)
  })

  async function refetch() {
    await fetchRoles()
    await fetchPersons()
    await fetchRights()
    const fetchedId = (await supabase.auth.getUser())?.data.user?.id
    userId.value = fetchedId ?? ''
    const roleIds = rights.value.find((r) => r.user_id === userId.value)?.role_ids
    if (roleIds === undefined) return
    await fetchacl(roleIds)
    if (isAllowed('READ', 'RIGHTS')) joinTables()
  }

  supabase.auth.onAuthStateChange((state) => {
    if (!state.localeCompare('SIGNED_OUT')) {
      persons.value = []
      rights.value = []
      personWithRights.value = []
    }
  })

  async function fetchRoles() {
    try {
      const { data, error } = await supabase.from('rights_role').select('*').neq('id', 10000).neq('id', 11000)
      if (error) throw error
      if (data) roles.value = data
    } catch (error: any) {
      console.error(error)
    }
  }

  async function fetchPersons() {
    try {
      const { data, error } = await supabase.from('person').select('*').eq('is_visible', true)
      if (error) throw error
      if (data) persons.value = data
    } catch (error: any) {
      console.error(error)
    }
  }

  async function fetchRights() {
    try {
      const { data, error } = await supabase.from('rights_user_role').select('*')
      if (error) throw error
      if (data) rights.value = data
    } catch (error: any) {
      console.error(error)
    }
  }

  async function fetchacl(roles: number[]) {
    try {
      const { data, error } = await supabase.from('rights_access_control_list').select('*').in('role_id', roles)
      if (error) throw error
      userRights.value = data
    } catch (error) {
      console.error(error)
    }
  }

  function joinTables() {
    personWithRights.value = []
    for (const person of persons.value) {
      if (!person.uuid) continue
      const userRights = rights.value.find((r) => r.user_id === person.uuid)
      if (!userRights) continue
      const role2 = roles.value.filter((r) => userRights.role_ids.includes(r.id))
      const newEntry: PersonWithRights = {
        id: person.id,
        user_id: person.uuid,
        mail: person.mail ?? '',
        first_name: person.first_name ?? '',
        last_name: person.last_name ?? '',
        roles: role2,
        assignedBranches: userRights.assigned_branch_offices,
        assignedGroups: userRights.assigned_groups,
      }
      personWithRights.value.push(newEntry)
    }
  }

  async function updateUser(user_id: number, userData: Partial<Tables<'person'>>) {
    try {
      const { data, error } = await supabase.from('person').update(userData).eq('id', user_id).select().single()
      if (error) throw error
      const index = persons.value.findIndex((p) => p.id === user_id)
      if (index !== -1) {
        persons.value[index] = data
      }
      return data
    } catch (error) {
      console.error('Error updating user:', error)
    }
  }

  async function updateSingleUserRights(user_id: string, userData: Partial<Tables<'rights_user_role'>>) {
    try {
      const { data, error } = await supabase
        .from('rights_user_role')
        .update(userData)
        .eq('user_id', user_id)
        .select()
        .single()
      if (error) throw error
      const index = rights.value.findIndex((r) => r.user_id === user_id)
      if (index !== -1) {
        rights.value[index] = data
      }
      return data
    } catch (error) {
      console.error('Error updating user rights:', error)
    }
  }

  async function updateUserRights(update: PersonWithRights) {
    const usr = await updateUser(update.id, { first_name: update.first_name, last_name: update.last_name })
    const right = await updateSingleUserRights(update.user_id, {
      role_ids: update.roles.map((r) => r.id),
      assigned_groups: update.assignedGroups,
      assigned_branch_offices: update.assignedBranches,
    })
    if (!usr || !right) return
    const newRight: PersonWithRights = {
      id: update.id,
      mail: usr.mail ?? '',
      user_id: update.user_id,
      first_name: usr.first_name ?? '',
      last_name: usr.last_name ?? '',
      roles: right.role_ids.map((r) => {
        const role = roles.value.find((role) => role.id === r)
        return { id: r, role: role ? role.role : '' }
      }),
      assignedBranches: right.assigned_branch_offices,
      assignedGroups: right.assigned_groups,
    }
    const index = personWithRights.value.findIndex((p) => p.user_id === update.user_id)
    if (index !== -1) {
      personWithRights.value[index] = newRight
    }
  }

  //TODO: Bulkupsert to reduce time
  async function updateRights(newEntries: PersonWithRights[]) {
    for (const newEntry of newEntries) {
      try {
        const entryForSupabase: Omit<Tables<'rights_user_role'>, 'id'> = {
          user_id: newEntry.user_id,
          assigned_branch_offices: newEntry.assignedBranches,
          assigned_groups: newEntry.assignedGroups,
          role_ids: newEntry.roles.map((r) => r.id),
        }
        const { data, error } = await supabase
          .from('rights_user_role')
          .update(entryForSupabase)
          .eq('user_id', newEntry.user_id)
          .select()
          .single()
        if (error) throw error
        const person = personWithRights.value.find((p) => p.user_id === data.user_id)
        if (!person) return
        person.assignedGroups = newEntry.assignedGroups
        person.assignedBranches = newEntry.assignedBranches
        person.roles = newEntry.roles
      } catch (error: any) {
        console.error(error)
      }
    }
  }

  async function insertUser(
    userData: Partial<Tables<'person'>>,
    branches: number[],
    groups: number[],
    role_ids: number[]
  ) {
    try {
      if (!userData.mail) throw new Error('No email provided')
      const res = await invite(userData.mail)
      if (!res || !res.uuid) throw new Error('No valid UUID returned')
      const { data, error } = await supabase
        .from('person')
        .insert({ uuid: res.uuid as unknown as string, ...userData })
        .select()
        .single()
      if (error) throw error
      persons.value.push(data)
      const entryForSupabase: Omit<Tables<'rights_user_role'>, 'id'> = {
        user_id: data.uuid,
        assigned_branch_offices: branches,
        assigned_groups: groups,
        role_ids: role_ids,
      }
      const { data: rightsData, error: rightsError } = await supabase
        .from('rights_user_role')
        .insert(entryForSupabase)
        .select()
        .single()
      if (rightsError) throw rightsError
      rights.value.push(rightsData)
      const newRight: PersonWithRights = {
        id: data.id,
        mail: data.mail ?? '',
        user_id: data.uuid,
        first_name: data.first_name ?? '',
        last_name: data.last_name ?? '',
        roles: rightsData.role_ids.map((r) => {
          const role = roles.value.find((role) => role.id === r)
          return { id: r, role: role ? role.role : '' }
        }),
        assignedBranches: rightsData.assigned_branch_offices,
        assignedGroups: rightsData.assigned_groups,
      }
      personWithRights.value.push(newRight)
      return null
    } catch (error) {
      console.error('Error inserting user:', error)
      return error
    }
  }

  function isAllowed(method: CRUD, feature: FEATURE): boolean {
    const rights = userRights.value.filter((r) => r.name === feature)
    if (rights.length === 0) return false
    switch (method) {
      case 'CREATE':
        if (rights.find((r) => r.create)) return true
        break
      case 'READ':
        if (rights.find((r) => r.read)) return true
      case 'UPDATE':
        if (rights.find((r) => r.update)) return true
      case 'DELETE':
        if (rights.find((r) => r.delete)) return true

      default:
        return false
    }
    return false
  }

  function isSelf(id: string) {
    return !userId.value.localeCompare(id)
  }

  const invite = async (mail: string) => {
    return fetch('https://datenbagger.backend-sf.de/invite', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${(await supabase.auth.getSession()).data.session?.access_token}`,
      },
      body: JSON.stringify({ email: mail }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`)
        }
        return response.json()
      })
      .catch((error) => {
        console.error('Error:', error)
        return null
      })
  }

  return {
    roles,
    persons,
    rights,
    personWithRights,
    currentUserRight,
    userRights,
    initDone,
    fetchRoles,
    fetchPersons,
    fetchRights,
    updateRights,
    insertUser,
    refetch,
    isAllowed,
    isSelf,
    updateUserRights,
  }
})
