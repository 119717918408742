import moment from 'moment'

const formatDateTime = (date: Date): string => {
  if (moment(date).isSame(moment(new Date()), 'day')) {
    return moment(date).format('HH:mm')
  } else {
    return moment(date).format('DD.MM.YY')
  }
}

const formatTime = (date: Date): string => {
  return moment(date).format('HH:mm')
}

const formatDate = (date: Date): string => {
  return moment(date).format('DD.MM.YYYY')
}

const formatDateYYYYMMDD = (date: Date): string => {
  return moment(date).format('YYYY-MM-DD')
}

const formatDateMonthAndYearOnly = (date: Date): string => {
  return moment(date).format('MMMM YYYY')
}

const formatServiceDate = (date: Date): string => {
  return `${date.getMonth() + 1}/${date.getFullYear()}`
}

const getYears = (minusCurrent: number, plusCurrent: number): number[] => {
  const years = []
  const currentYear = new Date().getUTCFullYear()
  for (let i = -minusCurrent; i <= plusCurrent; i++) {
    years.push(currentYear + i)
  }
  return years
}

const isWeekend = (dateMonth: Date, day: number): boolean => {
  dateMonth = new Date(dateMonth)
  dateMonth.setDate(day)
  return dateMonth.getDay() === 0 || dateMonth.getDay() === 6
}

const addOrSubtractStringDate = (dateString: string, days: number, add = true): string => {
  const date = new Date(dateString)
  let result: Date
  if (add) result = new Date(date.getTime() + 86400000 * days)
  else result = new Date(date.getTime() - 86400000 * days)
  return formatDateYYYYMMDD(result)
}

const months = [
  { label: 'Januar', id: 0 },
  { label: 'Februar', id: 1 },
  { label: 'März', id: 2 },
  { label: 'April', id: 3 },
  { label: 'Mai', id: 4 },
  { label: 'Juni', id: 5 },
  { label: 'Juli', id: 6 },
  { label: 'August', id: 7 },
  { label: 'September', id: 8 },
  { label: 'Oktober', id: 9 },
  { label: 'Novemeber', id: 10 },
  { label: 'Dezember', id: 11 },
]

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
const setYear = (year: number, obj: any, property_name: string): void => {
  let date_string: string = obj[property_name]
  const newDate: Date = new Date(date_string || new Date())
  if (!date_string) {
    newDate.setDate(1)
  }
  newDate.setUTCFullYear(year)
  date_string = newDate.toISOString()
  obj[property_name] = date_string.split('T')[0]
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
const setMonth = (month: number, obj: any, property_name: string): void => {
  let date_string: string = obj[property_name]
  const newDate: Date = new Date(date_string || new Date())
  if (!date_string) {
    newDate.setDate(1)
    newDate.setUTCFullYear(newDate.getUTCFullYear() + 1)
  }
  newDate.setUTCMonth(month)
  date_string = newDate.toISOString()
  obj[property_name] = date_string.split('T')[0]
}

const getMonthDifference = (startDate: Date, endDate: Date): number => {
  return moment(startDate).diff(moment(endDate), 'months')
}

export {
  getMonthDifference,
  formatDateTime,
  formatDate,
  formatDateYYYYMMDD,
  formatDateMonthAndYearOnly,
  formatServiceDate,
  getYears,
  months,
  setYear,
  setMonth,
  formatTime,
  isWeekend,
  addOrSubtractStringDate,
}
