import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import ChangePassword from '@/views/ChangePassword.vue'
import Login from '@/views/Login.vue'
import DeviceNav from '@/views/devices/Nav.vue'
import { supabase } from '@/supabase'
import { useRightsStore } from '@/store/rights'
import { useDeviceStore } from '@/store/device'

const LiveTracking = () => import('@/components/LiveTracking.vue')
const Replay = () => import('@/components/Replay.vue')
const SalaryReview = () => import('@/components/SalaryReview.vue')
const DeviceCreate = () => import('@/views/devices/Create.vue')
const DeviceView = () => import('@/views/devices/View.vue')
const DeviceEdit = () => import('@/views/devices/Edit.vue')
const InventoryView = () => import('@/views/inventory/View.vue')
const RightView = () => import('@/views/rights/View.vue')
const CustomerView = () => import('@/views/customer/View.vue')
const MaintenanceView = () => import('@/views/maintenance/View.vue')
const TestView = () => import('@/views/Test.vue')
const DispatchView = () => import('@/views/dispo/dispatch/View.vue')
const DashboardView = () => import('@/views/dashboard/View.vue')
const OrderView = () => import('@/views/dispo/order/View.vue')
const SiteView = () => import('@/views/dispo/site/View.vue')
const SiteEdit = () => import('@/views/dispo/site/Edit.vue')
const DirvingOrder = () => import('@/views/dispo/dispatch/DrivingOrder.vue')
const Fleet = () => import('@/views/fleet/View.vue')
const SalesView = () => import('@/views/sales/View.vue')

const routes: RouteRecordRaw[] = [
  { path: '/login', component: Login },
  { path: '/changePassword', component: ChangePassword },
  { path: '/home', component: DashboardView },
  { path: '/live', component: LiveTracking },
  { path: '/replay', component: Replay },
  { path: '/replay/:deviceId', component: Replay },
  { path: '/replay/:deviceId/:from', component: Replay },
  { path: '/replay/:deviceId/:from/:to', component: Replay },
  { path: '/salaryReview', component: SalaryReview },
  { path: '/salaryReview/:deviceId', component: SalaryReview },
  { path: '/devices', component: DeviceNav },
  { path: '/devices/create', component: DeviceCreate },
  { path: '/devices/:id', component: DeviceView, props: true },
  { path: '/devices/:id/edit', component: DeviceEdit, props: true },
  { path: '/inventory', component: InventoryView, props: true },
  { path: '/rights', component: RightView },
  { path: '/customer', component: CustomerView },
  { path: '/maintenance', component: MaintenanceView },
  { path: '/test', component: TestView },
  { path: '/orders', component: OrderView },
  { path: '/dispatch/:tab', component: DispatchView, props: true },
  { path: '/mySites', component: SiteView },
  { path: '/dispatch/driving-order/:date/:mode', component: DirvingOrder, props: true },
  { path: '/fleet', component: Fleet },
  { path: '/sales', component: SalesView },
  { path: '/sites', component: SiteEdit },
]

export const router = createRouter({
  history: createWebHistory(),
  routes,
})

const canAccess = async (to: any): Promise<boolean> => {
  const rightsStore = useRightsStore()
  await rightsStore.initDone
  if (to.fullPath === '/live') {
    return rightsStore.isAllowed('READ', 'DEVICE')
  } else if (to.fullPath.startsWith('/replay')) {
    return rightsStore.isAllowed('READ', 'DEVICE.HISTORY')
  } else if (to.fullPath.startsWith('/salaryReview')) {
    return rightsStore.isAllowed('READ', 'SALARYREVIEW')
  } else if (to.fullPath === '/devices/create') {
    return rightsStore.isAllowed('CREATE', 'DEVICE')
  } else if (to.fullPath.startsWith('/devices')) {
    return rightsStore.isAllowed('READ', 'DEVICE')
  } else if (to.fullPath === '/inventory') {
    return rightsStore.isAllowed('READ', 'DEVICE')
  } else if (to.fullPath === '/rights') {
    return rightsStore.isAllowed('READ', 'RIGHTS')
  } else if (to.fullPath === '/customer') {
    return rightsStore.isAllowed('READ', 'CUSTOMER')
  } else if (to.fullPath === '/test') {
    return rightsStore.isAllowed('READ', 'DEV')
  } else if (to.fullPath === '/orders') {
    return rightsStore.isAllowed('READ', 'ORDERS') || rightsStore.isAllowed('READ', 'ORDERS.OWN')
  } else if (to.fullPath.startsWith('/dispatch')) {
    return rightsStore.isAllowed('READ', 'DISPATCH')
  } else if (to.fullPath === '/mySites') {
    return rightsStore.isAllowed('READ', 'SITES.OWN')
  } else if (to.fullPath === '/sales') {
    return rightsStore.isAllowed('READ', 'SALES')
  } else if (to.fullPath === '/fleet') {
    return rightsStore.isAllowed('READ', 'FLEET')
  } else if (to.fullPath === '/sites') {
    return rightsStore.isAllowed('READ', 'SITES')
  }
  return true
}

router.beforeEach(async (to, from, next) => {
  const user = (await supabase.auth.getUser())?.data.user
  if (to.fullPath.includes('/#')) next(to.fullPath.replace('/#', ''))
  if (to.fullPath.includes('access_token')) {
    const splittedPath = to.fullPath.substring(1).split('&')
    let token_type = ''
    let refreshToken = ''
    let type = ''
    for (const subPath of splittedPath) {
      if (subPath.split('=')[0] === 'refresh_token') refreshToken = subPath.split('=')[1]
      if (subPath.split('=')[0] === 'token_type') token_type = subPath.split('=')[1]
      if (subPath.split('=')[0] === 'type') type = subPath.split('=')[1]
    }
    // FIXME:
    // if (token_type == 'bearer') {
    //   await supabase.auth.signIn({ refreshToken })
    //   if (type === 'recovery') {
    //     next('/changePassword?t=recovery')
    //   } else
    if (type === 'invite') {
      next('/changePassword?t=invite')
    }
    // }
    next()
  } else if (user?.aud.includes('authenticated')) {
    if (to.fullPath.includes('/?id=')) {
      const ident = to.fullPath.split('=')[1].replaceAll(':', '')
      const deviceStore = useDeviceStore()
      await deviceStore.fetchDevices()
      const device_id = deviceStore.devices.find((d) => d.ident?.toUpperCase() === ident.toUpperCase())?.id
      if (!device_id) next('/')
      next(`/devices/${device_id}/edit`)
    }
    const hasAccess = await canAccess(to)
    const defaultPath = import.meta.env.VITE_DEFAULT_STARTING_PAGE
      ? import.meta.env.VITE_DEFAULT_STARTING_PAGE
      : '/home'
    if (hasAccess) {
      if (to.fullPath === '/') next(defaultPath)
      else next()
    } else {
      console.error('No access to ', to.fullPath)
      next(defaultPath) //FIXME: error page
    }
  } else if (to.fullPath.includes('login')) {
    next()
  } else {
    //FIXME return to page after login
    return next({ path: '/login', query: { redirect: to.fullPath } })
  }
})
